{
  "title": {
    "pl": "Od ponad 28 lat dbamy<br /><span>o Twój uśmiech</span>",
    "en": "We have been taking care of your smile<br /><span>for over 28 years</span>"
  },
  "subtitle": {
    "pl": "Jesteśmy dostępni <strong>7 dni</strong> w&nbsp;tygodniu, również w&nbsp;<strong>weekendy i&nbsp;święta!</strong>",
    "en": "We are available <strong>24&nbsp;hours</strong> a&nbsp;day,<br /><strong>7 days</strong> a&nbsp;week"
  },
  "info": {
    "pl": "Po więcej informacji zapraszamy na stronę: ",
    "en": "For more information, please visit:"
  }
}
