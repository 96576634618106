import content from '../content.json';

document.addEventListener('DOMContentLoaded', () => {
  const buttons = document.querySelectorAll('.langButton');
  const title = document.getElementById('title');
  const subtitle = document.getElementById('subtitle');
  const info = document.getElementById('info');
  const langs = ['en', 'pl'];
  let lang =
    (langs.includes(window.navigator.language.replace(/-[a-zA-Z]+/, '')) &&
      window.navigator.language.replace(/-[a-zA-Z]+/, '')) ||
    'pl';

  const setContent = () => {
    title.innerHTML = content.title[lang];
    subtitle.innerHTML = content.subtitle[lang];
    info.innerHTML = content.info[lang];
  };

  setContent();

  buttons.forEach((btn) => {
    btn.addEventListener('click', (e) => {
      e.preventDefault;
      lang = btn.innerText.toLowerCase();
      setContent();
    });
  });
});
